/**
* Generated automatically at built-time (2024-10-08T07:20:02.053Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "Black-friday",templateKey: "sites/98-b261c3ed-2832-4a5a-a0b2-2ce585de9b6e"};