/**
* Generated automatically at built-time (2024-10-08T07:20:02.060Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "guide-spis",templateKey: "sites/98-ac4f9789-64e4-4781-ba92-f7fc9e22d00a"};